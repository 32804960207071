exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contactless-mobile-app-js": () => import("./../../../src/pages/contactless-mobile-app.js" /* webpackChunkName: "component---src-pages-contactless-mobile-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-web-chat-js": () => import("./../../../src/pages/web-chat.js" /* webpackChunkName: "component---src-pages-web-chat-js" */),
  "component---src-pages-zbot-conversation-js": () => import("./../../../src/pages/zbot-conversation.js" /* webpackChunkName: "component---src-pages-zbot-conversation-js" */)
}

